import React from "react";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

function Card({
  image = { alt: "", image: "/img/" },
  heading,
  date,
  url,
  className,
  ...rest
}) {
  return (
    <Link to={url} className={`card bg-black text-light ${className}`} {...rest}>
      {image && image.image && (
        <div className="image-container off-ratio off-ratio-1x1">
          <PreviewCompatibleImage imageInfo={image} objectFit="cover" style={{height: '100%'}} className="card-img" />
        </div>
      )}
      {heading === "Next" && (
        <div className="card-header text-end border-0">
          Next<span className="bi bi-arrow-right-short align-top"></span>
        </div>
      )}
      {heading === "Previous" && (
        <div className="card-header text-start border-0">
          <span className="bi bi-arrow-left-short align-top"></span>Previous
        </div>
      )}
      {heading !== "Next" && heading !== "Previous" && (
        <h3 className="card-header border-0">{heading}</h3>
      )}
    </Link>
  );
}

export default Card;
