import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Content from "../components/Content";
import Card from "../components/Card";

// eslint-disable-next-line
const PortfolioListTemplate = ({ 
  posts = [],
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="page page--portfolio-list">
      {helmet || ""}
      <h1 className="title container-md">{title}</h1>
      <section className="container-md px-3">
        <PageContent content={content} />
        <div
          className={`cards`}
        >
          {posts.map(({ node: post }, idx) => {
            const fm = post.frontmatter
            return (
              <Card
                image={fm.featuredimage}
                heading={fm.title}
                date={fm.date}
                to={post.fields.slug}
                key={idx}
              />
            )
          })}
        </div>
      </section>
    </section>
  );
}

class PortfoliosRoute extends React.Component {
  render() {
    // const { markdownRemark: post } = this.props.data;
    // const { frontmatter: fm } = this.props.data.markdownRemark
    // const { edges: posts } = data.allMarkdownRemark
    // const category = pageContext.category;
    const posts = this.props.data.allMarkdownRemark.edges;
    const category = this.props.pageContext.category;

    return (
      <Layout>
        <PortfolioListTemplate
          title={category}
          posts={posts}
          helmet={
            <Helmet>
              <title>{`${category} Portfolio`}</title>
              <meta
                name="description"
                content={`Browse the collection of ${category?.toLowerCase()} portfolio items.`}
              />
              {category ? <meta property="og:title" content={category} /> : null}
            </Helmet>
          }
        />
      </Layout>
    )
  }
}

export default PortfoliosRoute

export const portfoliosPageQuery = graphql`
  query PortfoliosPage($categoryRegex: String!) {
    # markdownRemark(id: { eq: $id }) {
    #   html
    #   frontmatter {
    #     title
    #     # category
    #   }
    # }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      # filter: { frontmatter: { 
      #   # templateKey: { eq: "portfolio-post" }
      #   category: { eq: "commissions" }
      # } }
      # filter: { frontmatter: { category: { in: [$category] } } }
      filter: {fields: {slug: {regex: $categoryRegex}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    quality: 85
                    formats: [AUTO, WEBP]
                    placeholder: BLURRED
                    aspectRatio: 1
                    # see - https://sharp.pixelplumbing.com/api-resize#resize
                    # transformOptions: { fit: CONTAIN, cropFocus: CENTER }
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

